<template>
  <div>
    <!-- <v-skeleton-loader v-if="listPayloadObj.loading"
      ref="skeleton"
      type="table"
      :tile="false"
      class="mx-auto"
    ></v-skeleton-loader> -->
    <compact-list :payload="listPayloadObj" :total="total" :key="reInitList" ref="submodule-compactlist"
     @delete="onDeleteListItem" :accesscontrol="listPayloadObj.accesscontrol" @listSortingEmitter="updateListWithSorting($event)"
    :hasMoreRecords="hasMoreRecords" @getCount="getRecordsCount" :countLoading="countLoading" :conditions="conditions"
    :showCount="showCount"
      >
   </compact-list>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent>
      <v-card tile>
        <v-toolbar dark :color="systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" class="px-2">
          <v-btn icon dark @click="closeDialogHandler" small>
            <v-icon :color="systemDetails && systemDetails.textcolor ? systemDetails.textcolor : 'white'">
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <v-toolbar-title :style="{ color: systemDetails && systemDetails.textcolor ? systemDetails.textcolor : 'white' }"
          class="body-1">
            {{ selectedObj.id ? `${$t('update')} ${moduleObj.name}` : `${$t('add')} ${moduleObj.name}` }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon small @click="emitActionHandler(selectedObj.id ? 'UPDATE' : 'SAVE')" :color="$vuetify.theme.dark ? '' : systemDetails.textcolor"
          v-if="selectedObj.id ? (moduleObj.accesscontrol && moduleObj.accesscontrol.edit) : (listPayloadObj.accesscontrol && listPayloadObj.accesscontrol.add)">
            <v-icon size="20"> mdi-checkbox-marked-circle-outline </v-icon>
          </v-btn>
        </v-toolbar>
        <submodule-form @subModuleRecordAccess="updateAccessControl" :isChildrenOftheModule="moduleObj.isChildrenOfTheModule" :moduleInfo="moduleObj" v-if="dialog" ref="sumboduleForm" :formId="selectedObj.id" :hasPermission="listPayloadObj.hasPermission"></submodule-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="relateDialog" width="700px" persistent>
      <v-card>
        <v-card-title class="px-4 pt-3 pb-2" :style="`background-color: ${$vuetify.theme.dark ? '' : systemDetails.themecolor}; color: ${$vuetify.theme.dark ? '' : systemDetails.textcolor}`">
          <span> {{ moduleObj.isChildrenOfTheModule ? $t('setAsChild') : $t('relate') }} </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-3">
          <v-form ref="formRef">
            <template v-if="moduleObj.isChildrenOfTheModule">
              <v-autocomplete :label="$t('select')" outlined hide-details dense :items="listOfRelative" item-text="text" item-value="value" v-model="childrenId" :rules="$_requiredValidation" @keyup="onKeyup" :search-input.sync="search">
                <template v-slot:append-item>
                  <div v-if="showRenderLoadMore" v-intersect="onIntersect" class="pa-4 teal--text" @click="onIntersect()">
                      Loading more items ...
                  </div>
                </template>
              </v-autocomplete>
            </template>
            <template v-else>
              <v-autocomplete :label="$t('select')" outlined hide-details dense :items="listOfRelative" item-text="text" item-value="value"
                multiple v-model="relateObj.relativeRecords" :rules="$_multiSelectValidation" @keyup="onKeyup">
                <template v-slot:append-item>
                  <div v-if="showRenderLoadMore" v-intersect="onIntersect" class="pa-4 teal--text" @click="onIntersect()">
                     Loading more items ...
                  </div>
                </template>
              </v-autocomplete>
              <v-checkbox :label="$t('setAsMain')" outlined hide-details dense v-model="relateObj.isMain" v-if="relateObj.relativeRecords.length === 1"></v-checkbox>
            </template>
            <!-- <v-autocomplete :label="$t('select')" outlined hide-details dense :items="listOfRelative" item-text="text" item-value="value" multiple v-model="relativeRecords" :rules="$_multiSelectValidation"></v-autocomplete> -->
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn color="primary" class="mr-2 text-capitalize py-4" @click="saveRelationsHandler" small>
              {{ $t('save') }}
            </v-btn>
            <v-btn color="error" @click="closeRelateHandler" small class="text-capitalize py-4">
              {{ $t('close') }}
            </v-btn>
          </v-flex>
        </v-card-actions>
     </v-card>
    </v-dialog>
    <!-- DELETE WARNING -->
    <v-dialog v-model="showDelete" persistent max-width="400px" class="mt-0">
      <v-card>
        <v-card-title>
          {{ $t('deleteConfirm') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          {{ $t('areYouSure') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" id="deleteConfirmSubmodule" :loading="loading" @click="confirmDelete">{{ $t('yes') }}</v-btn>
          <v-btn color="primary" id="deleteCloseSubmodule" @click="showDelete=false, deletePayload=null">{{ $t('no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommonFunctions from './mixin'
import { mapGetters } from 'vuex'
export default {
  props: ['moduleObj', 'hasPermissionTo', 'parentModuleItem', 'accesscontrol'],
  mixins: [CommonFunctions],
  data () {
    return {
      total: 0,
      support: 'support',
      parentModuleObj: {
        moduleName: this.$route.params.name,
        moduleId: this.$route.params.id,
        recordId: this.$route.params.record_id
      },
      relateDialog: false,
      relativeRecords: [],
      loading: false,
      dialog: false,
      selectedObj: {},
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.COMMON_INDEX) ? this.$store.state.common.COMMON_INDEX : { itemsPerPage: 8, page: 1 },
      MODULE: 'submodules',
      headers: [],
      listOfRelative: [],
      listPayloadObj: {
        hideHeader: false,
        relateClickHandler: this.openRelateHandler,
        unrelateClickHandler: this.unRelateRelationsHandler,
        addClickHandler: this.openDialogHandler,
        editHandler: (item) => this.getSingleRecordHandler,
        redirectLink: `/module/${this.moduleObj.name}/${this.moduleObj._id}/view`,
        sortClickHandler: (type) => this.getListHandler({ sortBy: type, skip: 0, limit: 25 }),
        refreshHandler: this.getListHandler,
        moduleTitle: '',
        hasNoNext: false,
        loading: false,
        list: [],
        pagination: {},
        storeIndex: 'COMMON_INDEX',
        items: [],
        // hasFilter: false,
        hasFilter: true,
        isCustomModule: true, // flag to show the relate unrelate options
        moduleIcon: '', // mdi-cube,
        hasPermission: false,
        hasSearch: true,
        accesscontrol: {
          view: true,
          add: false,
          edit: false,
          delete: false
        },
        completeList: [],
        sortBy: 1,
        sortByField: 0,
        isSubmodule: true,
        isChildrenOfTheModule: this.moduleObj.isChildrenOfTheModule,
        moduleName: this.moduleObj.name
      },
      deleteSuccessPagination: null,
      showDelete: false,
      deletePayload: null,
      reInitList: 0,
      hasManagePermission: false,
      relateObj: {
        relativeRecords: [],
        isMain: false
      },
      childrenId: null,
      currentModule: {},
      hasMoreRecords: false,
      countLoading: false,
      showCount: false,
      limit: 30,
      skip: 0,
      searchTerm: '',
      listData: [],
      showRenderLoadMore: true,
      search: '',
      conditions: [],
      listOfAllActiveFields: []
    }
  },
  computed: {
    ...mapGetters(['listOfModules', 'userDetails', 'getUsers', 'systemDetails'])
  },
  components: {
    'submodule-form': () => import('./Form'),
    'compact-list': () => import('@/components/CompactList')
  },
  watch: {
    pagination: {
      handler (val) {
        const model = {
          type: 'COMMON_INDEX',
          data: val
        }
        this.$store.commit('savePagination', model)
      },
      deep: true
    },
    '$store.state.common.listOfModules' (val) {
      this.setPermission()
    }
  },
  created () {
    this.loadFieldsAndData()
    // this.getFieldsList(this.moduleObj._id)
    // this.getListOfRelativeRecords()
  },
  mounted () {
    var currentModule = this.$formatter.cloneVariable(this.listOfModules.find(x => x._id === this.moduleId))
    if (currentModule) {
      this.listPayloadObj.profileEnabled = currentModule.enableprofile
      this.currentModule = currentModule
      this.listPayloadObj.currentModule = currentModule
    }
    this.setPermission()
    this.$store.dispatch('getUsers')
    this.listPayloadObj.moduleTitle = this.moduleObj.plural_name
    this.listPayloadObj.moduleIcon = this.moduleObj.icon
    // const text = 'deleteMsg'
    this.$root.$on('deleteItems', (data) => {
      if (data.module === this.MODULE) {
        // this.pagination = data.pagination
        this.deleteSuccessPagination = data.pagination
        this.deletePayload = { ids: [data.id], url: `moduledata/${this.moduleObj.name}/delete_multiple`, module: this.MODULE, isSoftDelete: true }
        this.showDelete = true
      }
    })
    /* this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE) {
        this.pagination = this.deleteSuccessPagination
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        this.getListHandler()
      }
    }) */
    this.$eventBus.$on('saveUpdate', (data) => {
      this.closeDialogHandler()
      this.getListHandler()
    })
    this.$eventBus.$on('loadList', (data) => {
      this.getListHandler(data)
    })

    // Filters
    this.$eventBus.$on('showHideFilter', () => {
      // this.showFilter = !this.showFilter
      this.$root.$emit('filters', {
        fields: this.$formatter.cloneVariable(this.listOfAllActiveFields),
        callback: (conditions) => {
          this.conditions = conditions || []
          this.updatePageView({ conditions: this.conditions, type: 'filters' })
        }
      })
    })
    // reset filters
    this.$eventBus.$on('resetFilter', (data) => {
      this.listOfAllActiveFields = data
    })
  },
  methods: {
    async loadFieldsAndData () {
      await this.getFieldsList(this.moduleObj._id)
      this.getListOfRecords({ type: this.SUBMODULELIST })
    },
    onIntersect () {
      this.skip += 30
      this.getList()
    },
    onKeyup (event) {
      const key = event.key
      if ((/^[a-zA-Z0-9]$/.test(key)) || key === 'Backspace') {
        this.searchTerm = this.search
      }
      this.skip = 0
      if (key !== 'Control' && key !== 'Alt' && key !== 'Shift') {
        this.getList()
      }
    },
    onDeleteListItem (paginationObj) {
      this.pagination = paginationObj
      this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'deleteMsg' })
      this.getListHandler()
    },
    updateAccessControl (accesscontrol) {
      this.$set(this.moduleObj, 'accesscontrol', accesscontrol)
    },
    getListHandler (payload) {
      // this.listPayloadObj.sortBy = sortBy || this.listPayloadObj.sortBy
      // this.loadList({ type: this.SUBMODULELIST, sortBy: this.listPayloadObj.sortBy })
      this.listPayloadObj = Object.assign(this.listPayloadObj, { ...payload })
      this.getListOfRecords({ type: this.SUBMODULELIST, ...payload })
      this.showCount = false
    },
    emitActionHandler () {
      this.$root.$emit('saveFormHandler')
      this.showCount = false
    },
    /* getListHandler (paginationObj) {
      this.listPayloadObj.loading = true
      this.listPayloadObj.list = []
      this.listPayloadObj.module = this.MODULE
      const pagination = paginationObj && paginationObj.itemsPerPage ? paginationObj : this.pagination
      this.listPayloadObj.pagination.page = pagination.page
      this.pagination.page = pagination.page
      const pageVal = pagination.page // search ? 1 :
      const query = [
        { $match: { Relations: { $elemMatch: { _id: { $eq: this.parentModuleObj.recordId } } } } },
        { $facet: { data: [{ $skip: ((pageVal - 1) * pagination.itemsPerPage) }], count: [{ $count: 'count' }] } }
      ]
      this.$api.execute('post', `moduledata/${this.moduleObj.name}/query`, query).then(response => {
        if (response.data && response.data.length > 0) {
          const { data } = response.data[0]
          data.forEach(element => {
            const _id = element._id
            let result = []
            for (var property in element.data) {
              // eslint-disable-next-line
              if (element.data.hasOwnProperty(property)) {
                result = this.headers.find(x => x.value === property)
                const item = {}
                if (result) {
                  if (result.type === 5 && result.default_value) {
                    item.value = element.data[property]
                    item.default_value = result.default_value
                    element.data[property] = this.getValues(item)
                  }
                  if (result.type === 15) element.data[property] = this.getUsername(element.data[property], result.default_value)
                }
              }
            }
            this.listPayloadObj.list.push({ ...element.data, _id })
          })
          this.listPayloadObj.completeList = this.$formatter.cloneVariable(this.listPayloadObj.list)
        }
      }).finally(() => {
        this.listPayloadObj.loading = false
      })
    }, */
    getList () {
      this.$api.execute('get', `/moduledata/${this.parentModuleObj.moduleName}/get_unrelated/${this.parentModuleObj.recordId}?relation_module=${this.moduleObj.name}&skip=${this.skip}&limit=${this.limit}&searchTerm=${this.searchTerm}`).then(response => {
        response.data.data.forEach(element => {
          if (this.moduleObj.name === this.CONTACT) {
            this.listData.push({ text: `${element.data.name} ${element.data.last_name || ''}`, value: element._id })
          } else {
            if (element.data.name) {
              this.listData.push({ text: element.data.name, value: element._id })
            }
          }
        })
        if (this.moduleObj.name !== this.CONTACT) {
          this.listData = [...this.listData]
        }
        if (response.data.total === this.skip || response.data.total < this.skip || response.data === []) {
          this.showRenderLoadMore = false
        }
        this.listOfRelative = this.listData
      })
    },
    // getListOfRelativeRecords () {
    //   const list = []
    //   if (this.moduleObj.isChildrenOfTheModule) {
    //     this.$api.execute('get', `/moduledata/${this.parentModuleObj.moduleName}/get_orphan_records/${this.parentModuleObj.recordId}`).then(response => {
    //       response.data.forEach(element => {
    //         if (this.moduleObj.name === this.CONTACT) {
    //           list.push({ text: `${element.data.name} ${element.data.last_name || ''}`, value: element._id })
    //         } else list.push({ text: element.data.name, value: element._id })
    //       })
    //       this.listOfRelative = list
    //     })
    //   } else {
    //     this.$api.execute('get', `/moduledata/${this.parentModuleObj.moduleName}/get_unrelated/${this.parentModuleObj.recordId}?relation_module=${this.moduleObj.name}`).then(response => {
    //       response.data.forEach(element => {
    //         if (this.moduleObj.name === this.CONTACT) {
    //           list.push({ text: `${element.data.name} ${element.data.last_name || ''}`, value: element._id })
    //         } else list.push({ text: element.data.name, value: element._id })
    //       })
    //       this.listOfRelative = list
    //     })
    //   }
    //   // this.$api.execute('get', `/moduledata/${this.parentModuleObj.moduleName}/get_unrelated/${this.parentModuleObj.recordId}?relation_module=${this.moduleObj.name}`).then(response => {
    //   //   const list = []
    //   //   response.data.forEach(element => {
    //   //     list.push({ text: element.data.name, value: element._id })
    //   //   })
    //   //   this.listOfRelative = list
    //   // })
    // },
    openDialogHandler () {
      this.dialog = true
    },
    closeDialogHandler () {
      if (this.$refs['submodule-compactlist']) this.$refs['submodule-compactlist'].selections = []
      setTimeout(() => {
        this.selectedObj.id = undefined
        this.dialog = false
      }, 100)
    },
    openRelateHandler () {
      this.relateDialog = true
      this.getList()
    },
    closeRelateHandler () {
      this.$refs.formRef.reset()
      this.relateDialog = false
    },
    saveRelationsHandler () {
      if (this.$refs.formRef.validate()) {
        // const model = { ids: this.$formatter.cloneVariable(this.relativeRecords) }
        // this.$api.execute('post', `moduledata/${this.moduleObj.name}/relate?relation_module=${this.parentModuleObj.moduleName}&relation_id=${this.parentModuleObj.recordId}`, model).then(response => {
        //   this.getListOfRelativeRecords()
        //   this.getListHandler()
        //   this.closeRelateHandler()
        // })
        this.loading = true
        const relateObj = this.$formatter.cloneVariable(this.relateObj)
        if (this.moduleObj.isChildrenOfTheModule) {
          this.$api.execute('post', `moduledata/${this.moduleObj.name}/set_as_child/${this.childrenId}?parent_id=${this.parentModuleObj.recordId}`).then(response => {
            // this.getListOfRelativeRecords()
            this.getListHandler()
            this.loading = false
            this.showCount = false
            this.closeRelateHandler()
          })
        } else {
          const model = { ids: relateObj.relativeRecords }
          this.$api.execute('post', `moduledata/${this.moduleObj.name}/relate?relation_module=${this.parentModuleObj.moduleName}&relation_id=${this.parentModuleObj.recordId}&ismain=${relateObj.isMain || false}`, model).then(response => {
            // this.getListOfRelativeRecords()
            this.getListHandler()
            this.loading = false
            this.showCount = false
            this.closeRelateHandler()
          })
        }
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'validationIssue' })
    },
    getSingleRecordHandler ({ _id }) {
      this.selectedObj.id = _id
      setTimeout(() => {
        this.$emit('redirectionToModuleView', { moduleObj: this.moduleObj, _id })
        // this.$router.push(`/module/${this.moduleObj.name}/${this.moduleObj._id}/view/${_id}`)
        //   this.dialog = true
      })
    },
    setPermission () {
      let hasManagePermission = this.$formatter.permissionSetting(this.listOfModules, this.moduleObj._id, this.userDetails)
      this.listPayloadObj.accesscontrol = {
        add: hasManagePermission.add && this.hasPermissionTo.edit,
        view: hasManagePermission.view && this.hasPermissionTo.view,
        edit: hasManagePermission.edit && this.hasPermissionTo.edit,
        delete: hasManagePermission.delete && this.hasPermissionTo.delete
      }
      hasManagePermission = hasManagePermission && (this.hasPermissionTo && this.hasPermissionTo !== this.VIEW)
      this.listPayloadObj.hasPermission = hasManagePermission
      this.hasManagePermission = hasManagePermission
      this.listPayloadObj.editHandler = this.listPayloadObj.hasPermission ? this.getSingleRecordHandler : () => {}
    },
    unRelateRelationsHandler (selected) {
      const model = { ids: [selected._id] }
      if (this.moduleObj.isChildrenOfTheModule) {
        this.$api.execute('post', `moduledata/${this.moduleObj.name}/remove_as_child/${selected._id}`).then(response => {
          // this.getListOfRelativeRecords()
          this.getListHandler()
        }).finally(() => {
          this.unRelateDialog = false
          this.showCount = false
        })
      } else {
        this.$api.execute('post', `moduledata/${this.moduleObj.name}/unrelate?relation_module=${this.parentModuleObj.moduleName}&relation_id=${this.parentModuleObj.recordId}`, model).then(response => {
          // this.getListOfRelativeRecords()
          this.getListHandler()
        }).finally(() => {
          this.unRelateDialog = false
          this.showCount = false
        })
      }
      // this.$api.execute('post', `moduledata/${this.moduleObj.name}/unrelate?relation_module=${this.parentModuleObj.moduleName}&relation_id=${this.parentModuleObj.recordId}`, model).then(response => {
      //   this.getListOfRelativeRecords()
      //   this.getListHandler()
      // })
    },
    confirmDelete () {
      this.loading = true
      const model = this.deletePayload
      const text = 'deleteMsg'
      this.$store.dispatch('deleteHandler', model)
        .then(() => {
          this.pagination = this.deleteSuccessPagination
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
          this.showDelete = false
          this.getListHandler()
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateListWithSorting ({ sortBy, sortByField }) {
      setTimeout(() => {
        this.$set(this.listPayloadObj, 'sortBy', sortBy)
        this.$set(this.listPayloadObj, 'sortByField', sortByField)
      })
      this.getListOfRecords({ type: this.SUBMODULELIST })
      // this.listPayloadObj.sortBy = sortBy
      // this.listPayloadObj.sortByField = sortByField
    },
    getRecordsCount (data) {
      this.countLoading = data
      this.getListOfRecordsCount({ type: this.SUBMODULELIST, kanbanViewModel: null, subModuleFilters: this.getAppliedfilter ? this.getAppliedfilter : [] })
      this.listPayloadObj.loading = false
    },
    updatePageView ({ conditions }) {
      const appliedfilter = this.$formatter.cloneVariable(conditions)
      this.getAppliedfilter = conditions
      this.getListHandler({ subModuleFilters: appliedfilter })
      this.listPayloadObj.loading = true
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('saveUpdate')
    // this.$eventBus.$off('deleteSuccess')
    this.$root.$off('deleteItems')
    this.$eventBus.$off('loadList')
    this.$eventBus.$off('showHideFilter')
  }
}
</script>
